import { isObjectEmpty } from '~/utils/helpers'
import { ModelApiKeys } from '~/lib/sectionComponents'

export default function () {
  const resolveSectionComponent = (block: any) => {
    if (isObjectEmpty(block)) {
      return
    }

    const componentName = ModelApiKeys[block._modelApiKey]

    if (!componentName) {
      console.error(`Section component for model [${block._modelApiKey}] does not exist.`)
    } else {
      return resolveComponent(componentName)
    }
  }

  return {
    resolveSectionComponent
  }
}
