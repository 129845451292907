// https://github.com/nuxt/framework/issues/6711

export interface DatoCMSModelApiKeys {
  section_article_list: string
  section_body: string
  section_contact_form: string
  section_cta: string
  section_features_list: string
  section_title: string
  section_hero: string
  section_image_gallery: string
  section_logos_list: string
  section_media_block: string
  section_related_article: string
  section_testimonials_list: string
  section_video: string
  section_hero_center: string
  section_switcher_two: string
  section_links_list: string
  section_switcher_one: string
  section_getting_here: string
  section_hero_simple: string
  section_slider_list: string
  section_image_links_list: string
  section_experience_list: string
  section_offer_list: string
  section_offers_slider: string
  section_location: string
  section_newsletter: string
  section_destination_map: string
  section_event_list: string
  section_map_embed: string
  section_roadmap: string
  section_search: string
  section_routes_grid: string
  section_route: string
}

export const ModelApiKeys: DatoCMSModelApiKeys = {
  section_article_list: 'SectionArticles',
  section_body: 'SectionBody',
  section_contact_form: 'SectionContactForm',
  section_cta: 'SectionCta',
  section_features_list: 'SectionFeatures',
  section_title: 'SectionTitle',
  section_hero: 'SectionHero',
  section_image_gallery: 'SectionImageGallery',
  section_logos_list: 'SectionLogos',
  section_media_block: 'SectionMedia',
  section_related_article: 'SectionRelatedArticles',
  section_testimonials_list: 'SectionTestimonials',
  section_video: 'SectionVideo',
  section_hero_center: 'SectionHeroCenter',
  section_slider_list: 'SectionSlider',
  section_links_list: 'SectionLinks',
  section_switcher_one: 'SectionSwitcherOne',
  section_switcher_two: 'SectionSwitcherTwo',
  section_getting_here: 'SectionGettingHere',
  section_hero_simple: 'SectionHeroSimple',
  section_image_links_list: 'SectionImageLinks',
  section_experience_list: 'SectionExperiences',
  section_offer_list: 'SectionOffers',
  section_offers_slider: 'SectionOffersSlider',
  section_location: 'SectionLocation',
  section_newsletter: 'SectionNewsletter',
  section_destination_map: 'SectionDestinationMap',
  section_event_list: 'SectionEvents',
  section_map_embed: 'SectionMapEmbed',
  section_roadmap: 'SectionRoadmap',
  section_search: 'SectionSearch',
  section_routes_grid: 'SectionRoutesGrid',
  section_route: 'SectionRoutes'
}
